<template>
  <div>
      <!----- 搜索框 start------->
      <el-form :inline="true" class="search">
        <el-form-item label="关键字">
          <el-input v-model="searchData.name" placeholder="关键字搜索" clearable />
        </el-form-item>
          <el-form-item>
              <el-button @click="getImpress()">查询</el-button>
          </el-form-item>

          <el-form-item style="float: right">
              <el-button type="primary" @click.stop="clickCreate()">添加备用金</el-button>
          </el-form-item>
      </el-form>
      <!----- 搜索框 end------->

      <!----- 表格 start------->
      <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
          <el-table-column prop="id" label="ID" />
          <el-table-column label="账户">
            <template #default="scope">
              <div v-text="scope.row.person.name + '(' + scope.row.person.remark + ')'"></div>
              <div v-text="scope.row.person.account"></div>
            </template>
          </el-table-column>
          <el-table-column prop="balance" label="未核销金额" />
          <el-table-column prop="cost_price" label="已核销金额" />
          <el-table-column prop="created_at" label="创建时间" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button size="small" type="primary" @click="clickDecBalance(scope.row)">扣款</el-button>
            </template>
          </el-table-column>
      </el-table>

      <!----- 表格 end------->

      <!-------- 弹出框表单 start ---------->
      <el-dialog v-model="formData.show" title="增加备用金" fullscreen append-to-body>
          <el-form
              ref="impress"
              :rules="formData.rules"
              label-width="100px"
              :model="formData.data"
          >
            <el-form-item label="收款人" filterable prop="person_id">
              <el-select v-model="formData.data.person_id" filterable>
                <el-option
                    v-for="(item, key) in person"
                    :key="key"
                    :label="item.name + item.remark + '「' + item.account + '」'"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间" prop="date">
              <el-date-picker v-model="formData.data.date" value-format="YYYY-MM-DD" type="date"
                              placeholder="申请时间"/>
            </el-form-item>
            <el-form-item label="金额" prop="price">
              <el-input v-model="formData.data.price" type="number" placeholder="填写金额"/>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="formData.data.remark" placeholder="备注信息，必填"/>
            </el-form-item>
          </el-form>

          <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
          </template>
      </el-dialog>
      <!-------- 弹出框表单 end -------->

       <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="decFormData.show" title="扣除备用金" fullscreen append-to-body>
      <el-form
          ref="impress-dec"
          :rules="decFormData.rules"
          label-width="100px"
          :model="decFormData.data"
      >
        <el-form-item label="时间" prop="date">
          <el-date-picker v-model="decFormData.data.date" value-format="YYYY-MM-DD" type="date"
                          placeholder="申请时间"/>
        </el-form-item>
        <el-form-item label="金额" prop="price">
          <el-input v-model="decFormData.data.price" type="number" placeholder="填写金额"/>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="decFormData.data.remark" placeholder="备注信息，必填"/>
        </el-form-item>
      </el-form>

      <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDecDialog">取消</el-button>
                <el-button type="primary" @click="onDecSubmit">提交</el-button>
              </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
import {apiGetImpress, apiCreateImpress, apiUpdateImpress} from '@/api/impress'
import {getCompanyId} from "@/plugins/company"
import {apiGetProjectPersons} from "@/api/projectPersonApi"

  let formData = {
      company_id: getCompanyId(),
      person_id: '',
      price: '',
      date: '',
      remark: ''
  }

  export default {
      name: 'project.impress',
      created () {
        this.getImpress()
        this.getPersons()
      },
      data() {
          return {
              // 创建表单
              formData:{
                  lock: false,
                  data: formData,
                  rules: {
                    person_id: [{
                      required: true,
                      message: '请选择收款人',
                      trigger: 'blur',
                    }],
                    date: [{
                      required: true,
                      message: '请选择日期',
                      trigger: 'blur',
                    }],
                    price: [{
                      required: true,
                      message: '请填写金额',
                      trigger: 'blur',
                    }]
                  },
                  show: false,
              },
            decFormData:{
              lock: false,
              data: {
                id: '',
                price: '',
                date: '',
                remark: ''
              },
              rules: {
                date: [{
                  required: true,
                  message: '请选择日期',
                  trigger: 'blur',
                }],
                price: [{
                  required: true,
                  message: '请填写金额',
                  trigger: 'blur',
                }]
              },
              show: false,
            },
              person: [],
              tableData: [],
              searchData: {
                  company_id: getCompanyId(),
                  page: 1,
              }
          }
      },
      methods: {
          getImpress() {
              apiGetImpress(this.searchData).then((res) => {
                  this.tableData = res
              })
          },
          getPersons() {
            apiGetProjectPersons({per_page: 500, company_id: getCompanyId()}).then((res) => {
              this.person = res.data
            })
          },
          onSubmit()  {
              if (this.formData.lock) {return;}
              this.formLock()
              this.$refs['impress'].validate(valid => {
                  if (valid) {
                    apiCreateImpress(this.formData.data).then(() => {
                      this.$message.success('创建成功')
                      this.getImpress()
                      this.closeDialog()
                      this.formUnlock()
                    }).catch(() => {
                      this.formUnlock()
                    })
                  }
              })
          },
        onDecSubmit() {
          if (this.decFormData.lock) {return;}
          this.formLock()
          this.$refs['impress-dec'].validate(valid => {
            if (valid) {
              apiUpdateImpress(this.decFormData.data.id, this.decFormData.data).then(() => {
                this.$message.success('修改成功')
                this.getImpress()
                this.closeDecDialog()
                this.decFormUnlock()
              }).catch(() => {
                this.decFormUnlock()
              })
            }
          })
        },
          // 点击扣款
          clickDecBalance (row) {
            this.decFormData.data.id = row.id
            this.decFormData.show = true
          },
          // 点击创建
          clickCreate () {
              this.initDialog()
              this.showDialog()
          },
          formLock() {
              this.formData.lock = true
          },
          formUnlock() {
              this.formData.lock = false
          },
        decFormUnlock() {
          this.formData.lock = false
        },
          // 初始化弹出
          initDialog () {
              this.formData.data = formData
              this.closeDialog()
          },
          // 显示弹窗
          showDialog() {
              this.formData.show = true
          },
          // 关闭弹窗
          closeDialog() {
              this.formData.show = false
          },
          closeDecDialog () {
              this.decFormData.show = false
          }
      }
  }
</script>
