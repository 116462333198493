import {httpGet, httpPost, httpPut} from '@/plugins/request'


/**
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetImpress(query ={})
{
    return httpGet('/manage/project/impress', query)
}

/**
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateImpress(data ={})
{
    return httpPost('/manage/project/impress', data)
}

/**
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateImpress(id, data) {
    return httpPut(`/manage/project/impress/${id}`, data)
}
